import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Grid, Paper, Typography, Button, ListItem, CircularProgress } from '@mui/material';
import { IconButton, Collapse } from '@mui/material';
import { useTheme } from '@mui/material';
import { ExpandMore, ExpandLess, FileCopyRounded, Person, LabelRounded } from '@mui/icons-material';
import { HelpRounded, SettingsOverscanRounded, AddRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { ArrowDropDownRounded, FileCopy, EuroRounded, GroupRounded } from '@mui/icons-material';
import { PercentRounded, AttachMoneyRounded, CurrencyPoundRounded } from '@mui/icons-material';
import { User, HtmlContent, GeneralButton, UserChip, HtmlTooltip, PicturesCarousel, UserGroup } from '../Components';
import { RichTextField, DatePicker, TimePicker, DateTimePicker, SearchMenu, DebouncedTextField } from '../Components';
import { HtmlText } from '../Components';
import { RequiredIcon, TaskIcon, ProjectIcon, ProcessIcon } from '../Icons';
import { blue, grey, red, amber } from '@mui/material/colors';
import StateManager from '../StateManager';
import FileViewer from '../FileViewer';
import Uploader from '../Uploader';
import { useHistory } from 'react-router-dom';
import { StatusButton } from '../../Hubs/tasks/Components';
import { FormatDate, validateUkNumber, validateEmail } from '../Functions';
import DataSetField from './DataSetField';
import axios from 'axios';
import { isEmpty, isArray, isObject, toNumber, isString, isEqual } from 'lodash';
import moment from 'moment';
import TabbedSignature from './TabbedSignature';
import FieldTable from './FieldTable';
import ExternalUserField from './ExternalUserField';
import ProjectDialog from './ProjectDialog';
import TaskDialog from './TaskDialog';
import FormField from './FormField';
import FieldDataGrid from './FieldDataGrid';
import ReadUnderstoodField from './ReadUnderstoodField';
import PeopleCard from './PeopleCard';
import AssetIncident from './AssetIncident';
import TableDialog from './FieldGridDialog';
import ActionGroup, { Action } from './ActionGroup';
import ReadAndSign from './ReadAndSign';
import HumanBody from './HumanBodyMap';
import CheckboxItem from './CheckboxItem';

function GetOptionPath(option, allOptions) {
  if (!option) return [];

  if (!option.parentId || !isArray(allOptions)) return [option.text];

  const parent = allOptions.find((x) => x.id === option.parentId);

  return [...GetOptionPath(parent, allOptions), option.text];
}

const OptionTypes = ['dropbox', 'weightedList', 'tickbox', 'status'];

export default function Field({
  item,
  editable,
  onSave,
  onTableSave,
  hideCalculations,
  allItems,
  greyOut,
  withoutValue,
  external,
  stepId,
  assignedUsers,
  dueDate,
  assignedBy,
  activityId,
  activity,
  forTaskTemplate,
  activityInfo,
  onCreatedAction,
  onDeletedAction,
  hiddenItems,
  cellInfo,
  parentTaskId,
  executedActions,
  onExecutedActionDelete,
  allCreatedActions,
}) {
  const history = useHistory();
  const theme = useTheme();
  const timer = useRef();
  const lastResult = useRef([]);
  const [loading, setLoading] = useState(true);
  const [hasValue, setHasValue] = useState(false);
  const [text, setText] = useState('');
  const [number, setNumber] = useState('');
  const [date, setDate] = useState(null);
  const [dropboxValue, setDropboxValue] = useState('');
  const [tickboxValues, setTickboxValues] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null); // can be either string or array
  const [selectedCondition, setSelectedCondition] = useState({});
  const [filledForm, setFilledForm] = useState(null);
  const [processEntry, setProcessEntry] = useState(null);
  const [filledReadUnderstood, setFilledReadUnderstood] = useState(null);
  const [task, setTask] = useState();
  const [taskParams, setTaskParams] = useState({});
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [project, setProject] = useState();
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [taskExpanded, setTaskExpanded] = useState(false);
  const [taskStatus, setTaskStatus] = useState('Not started');
  const [dataSetRows, setDataSetRows] = useState();
  const [signature, setSignature] = useState({});
  const [table, setTable] = useState();
  const [startingEntry, setStartingEntry] = useState(false);
  const [error, setError] = useState(false);
  const [people, setPeople] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [assetIncident, setAssetIncident] = useState(null);
  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const [addedInfo, setAddedInfo] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [infoFiles, setInfoFiles] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [readDocuments, setReadDocuments] = useState([]);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);

  // global fields
  const [loadingOptions, setLoadingOptions] = useState(false);

  const allowMultipleUsers = item?.userMultiSelect;

  const noValue = <Typography style={{ fontSize: 14, color: red[400] }}>No value provided</Typography>;

  const fieldExecutedActions = useMemo(
    () => (isArray(executedActions) ? executedActions.filter((x) => x && x.fieldId === item.id) : []),
    [executedActions, item],
  );

  function save(value) {
    const params = { value, valueType: item.fieldType };

    // option type - include the selected options
    if (OptionTypes.includes(item.fieldType)) {
      if (isArray(value)) {
        params.selectedOptions = fieldOptions.filter((x) => value.includes(x.id));
      }

      if (isString(value) && !isEmpty(value)) {
        params.selectedOptions = fieldOptions.filter((x) => value === x.id);
      }
    }

    // weighted list - save weight
    if (item.fieldType === 'weightedList') {
      params.weight = fieldOptions.find(({ id }) => id === value)?.weight;
    }

    const resultsToStore = 3;
    lastResult.current.push(value);
    lastResult.current = lastResult.current.slice(-resultsToStore);

    onSave(item.id, params);
    setHasValue(true);
  }

  function getPrevValueText(value) {
    if (['text', 'textArea', 'number', 'phone', 'email'].includes(item.fieldType)) {
      return value;
    }

    if (item.fieldType === 'date') {
      return moment(value).format('DD/MM/yyyy');
    }

    if (item.fieldType === 'time') {
      return moment(value).format('hh:mm A');
    }

    if (item.fieldType === 'dropbox' || item.fieldType === 'weightedList') {
      return fieldOptions.find((o) => o.id === value)?.text;
    }

    if (item.fieldType === 'conditional' && isArray(item.conditions)) {
      return item.conditions.find((x) => x.id === value)?.text;
    }

    if (item.fieldType === 'tickbox' && Array.isArray(value)) {
      return value.map((x) => fieldOptions.find((o) => o.id === x)?.text).join('; ');
    }

    return '';
  }

  function isNumeric(str) {
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function toNumberFieldValue(str) {
    return isNumeric(str) ? toNumber(str) : '';
  }

  // need to run only the first time
  useEffect(() => {
    if (!editable) {
      setFieldOptions(isArray(item.options) ? item.options : []);
      return;
    }

    // for global fields - fetch the latest versions
    if (item.globalFieldId && OptionTypes.includes(item.fieldType)) {
      setLoadingOptions(true);
      const invitationId = localStorage.getItem('invitationId');
      axios
        .get(external ? '/external/fields/getGlobalFieldData' : '/fields/groups/getGlobalFieldData', {
          params: { globalFieldId: item.globalFieldId, invitationId },
        })
        .then(({ data }) => {
          setFieldOptions(isArray(data?.options) ? data.options : []);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
        })
        .finally(() => {
          setLoadingOptions(false);
        });
    } else {
      setFieldOptions(isArray(item.options) ? item.options : []);
    }
  }, [editable]); // eslint-disable-line

  useEffect(() => {
    setLoading(false);

    const value = item?.filledValue?.value;

    // the result we've just sent - return
    if (value != null && !isEmpty(lastResult.current) && lastResult.current.some((x) => isEqual(x, value))) {
      return;
    }

    if (item.type === 'table') {
      setTableRows(item.table?.rows || []);
      return;
    }

    if ((value == null || (isObject(value) && isEmpty(value))) && item?.fieldType !== 'actionGroup') {
      setSignature(null);
      setText('');
      setDate(null);
      setSelectedUsers(null);
      setDropboxValue('');
      setTickboxValues([]);
      setFiles([]);
      setNumber('');
      return;
    }
    setHasValue(true);

    if (item.type === 'info') {
      setInfoText(value?.infoText || '');
      setInfoFiles(isArray(value?.infoFiles) ? value.infoFiles : []);
      setAddedInfo(!isEmpty(value?.infoText) || !isEmpty(value.infoFiles));
      return;
    }

    if (['text', 'textArea', 'phone', 'email'].includes(item.fieldType)) {
      setText(value);
    }
    if (item.fieldType === 'time') {
      setDate(moment(value).toDate());
    }

    if (['datetime', 'date'].includes(item.fieldType)) {
      setDate(moment(value).toDate());
    }

    if (item.fieldType === 'user') {
      setSelectedUsers(value);
    }

    if (['dropbox', 'weightedList', 'status'].includes(item.fieldType)) {
      setDropboxValue(value);
    }

    if (item.fieldType === 'conditional') {
      //setSelectedConditionId(value);
      let option = item.conditions.find((x) => x.id === value);
      if (option) {
        setSelectedCondition(option);
      }
      setTask(item.filledValue.task);
      setProject(item.filledValue.project);
    }

    if (item.fieldType === 'number') {
      setNumber(toNumberFieldValue(value));
    }

    if (item.fieldType === 'calculation') {
      setText(value);
    }

    if (item.fieldType === 'tickbox') {
      setTickboxValues(value);
    }

    if (item.fieldType === 'image' || item.fieldType === 'file') {
      setFiles(value);
    }

    if (item.fieldType === 'dataSet') {
      setDataSetRows(isArray(item?.filledValue?.rows) ? item.filledValue : value);
    }

    if (item.fieldType === 'signature') {
      setSignature(value);
    }

    if (item.fieldType === 'table') {
      setTable(value);
    }

    if (item.fieldType === 'people') {
      setPeople(Array.isArray(value) ? value : []);
    }

    if (item.type === 'form') {
      setFilledForm(value);
    }

    if (item.type === 'ru') {
      setFilledReadUnderstood(value);
    }

    if (item.type === 'process') {
      setProcessEntry(value);
    }

    if (item.type === 'task') {
      setTaskStatus(value);
    }

    if (item.type === 'assetIncident') {
      setAssetIncident(value);
    }

    if (item.fieldType === 'readAndSign') {
      setReadDocuments(value);
    }

    if (item.fieldType === 'humanMap') {
      setSelectedBodyParts(value);
    }
  }, [item, item?.filledValue]); // eslint-disable-line

  function activateTimer(value) {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(save, 800, value);
  }

  function saveText(value) {
    setText(value);
    save(value);
  }

  function saveNumber(value) {
    setNumber(value);
    save(value);
  }

  function saveDate(value) {
    setDate(value);
    save(value);
  }

  function saveDropboxValue(value) {
    setDropboxValue(value);
    save(value);
  }

  function saveConditional(value) {
    setSelectedCondition(value);
    save(value.id);
  }

  function saveTaskStatus(value) {
    setTaskStatus(value);
    onSave(item.id, { value });
  }

  function saveReadDocuments(value) {
    setReadDocuments(value);
    save(value);
  }

  function saveBodyParts(value) {
    setSelectedBodyParts(value);
    save(value);
  }

  function handleTickbox(optionId, checked) {
    if (!editable) return;
    const result =
      item.selectType === 'multiple'
        ? checked
          ? [...tickboxValues, optionId]
          : tickboxValues.filter((x) => x !== optionId)
        : checked
        ? [optionId]
        : [];
    setTickboxValues(result);
    activateTimer(result);
  }

  function selectUser() {
    StateManager.selectUser((user) => {
      setSelectedUsers(user._id);
      save(user._id);
    });
  }

  function selectMultipleUsers() {
    StateManager.selectMultipleUsers(
      (res) => {
        setSelectedUsers(res.users);
        save(res.users);
      },
      {
        initiallySelected: isArray(selectedUsers)
          ? selectedUsers
          : !isEmpty(selectedUsers) && isString(selectedUsers)
          ? [selectedUsers]
          : [],
      },
    );
  }

  function uploadFiles(value) {
    setFiles(value);
    activateTimer(value);
  }

  function openProcess() {
    if (processEntry) {
      history.push(`/processes/ongoing/${processEntry._id}`);
    } else {
      startProcessEntry(item.processId);
    }
  }

  function startProcessEntry(processId) {
    if (startingEntry) return;
    setStartingEntry(true);
    axios
      .post('/process/startProcess', { processId, parentTaskId })
      .then((res) => {
        onSave(item.id, { value: { _id: res.data.ongoingProcess._id, number: res.data.ongoingProcess.number } });
        if (!forTaskTemplate) {
          setTimeout(() => {
            setStartingEntry(false);
            StateManager.setSuccessAlert('Process has been started');
            history.push(`/processes/ongoing/${res.data.ongoingProcess._id}`);
          }, 800);
        } else {
          StateManager.setSuccessAlert('Process has been started');
          setStartingEntry(false);
          setProcessEntry({ _id: res.data.ongoingProcess._id, number: res.data.ongoingProcess.number });
        }
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to start the process');
        setStartingEntry(false);
      });
  }

  function saveTask(taskData) {
    onSave(item.id, { value: selectedCondition.id, task: taskData });
    setTask(taskData);
    setHasValue(true);
  }

  function saveSetRows(rows) {
    setDataSetRows(rows);
    activateTimer(rows);
  }

  function saveFieldGrid(rows) {
    if (!onTableSave) return;
    setTableRows(rows);
    onTableSave(item.id, rows);
  }

  function saveSignature(value) {
    setSignature(value);
    save(value);
  }

  function saveTable(value) {
    setTable(value);
    save(value);
  }

  function saveProject(projectData) {
    onSave(item.id, {
      value: selectedCondition.id,
      project: projectData,
      templateId: selectedCondition.templateId,
    });
    setProject(projectData);
    setHasValue(true);
  }

  function copyPrevValue(value) {
    if (item.fieldType === 'text' || item.fieldType === 'textArea') {
      saveText(value);
    } else if (item.fieldType === 'number') {
      saveNumber(toNumberFieldValue(value));
    } else if (item.fieldType === 'date' || item.fieldType === 'time') {
      saveDate(moment(value).toDate());
    } else if (item.fieldType === 'dropbox' || item.fieldType === 'weightedList') {
      saveDropboxValue(value);
    } else if (item.fieldType === 'conditional') {
      let option = item.conditions.find((x) => x.id === value);
      if (option) {
        saveConditional(option);
      }
    } else if (item.fieldType === 'tickbox' && Array.isArray(value)) {
      setTickboxValues(value);
      save(value);
    }
  }

  function checkDeletingInfo() {
    if (isEmpty(infoText) && isEmpty(infoFiles)) {
      deleteInfo();
    } else {
      StateManager.setConfirm('You are about to discard your changes', deleteInfo);
    }
  }

  function deleteInfo() {
    setInfoText('');
    setInfoFiles([]);
    setAddedInfo(false);
    save({ infoText: '', infoFiles: [] });
  }

  if (loading) {
    return (
      <Grid container item>
        <Grid container component={Paper} variant="outlined" sx={{ borderRadius: 1, py: 1, px: 2, my: 1 }}>
          <CircularProgress color="primary" size={30} />
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'form') {
    return (
      <Grid container item alignItems="center">
        <Grid
          container
          component={Paper}
          variant="outlined"
          sx={{ my: 1, position: 'relative', border: 'none' }}
          id={item.id}
        >
          {item.required && editable && (
            <RequiredIcon style={{ display: 'flex', position: 'absolute', top: -10, left: -10 }} />
          )}
          <FormField
            params={item}
            filled={filledForm}
            stepId={stepId}
            onChange={(value) => onSave(item.id, { value })}
            editable={editable}
            forTaskTemplate={forTaskTemplate}
            parentTaskId={parentTaskId}
          />
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'ru') {
    return (
      <Grid container item alignItems="center">
        <Grid
          container
          component={Paper}
          variant="outlined"
          sx={{ my: 1, position: 'relative', border: 'none' }}
          id={item.id}
        >
          {item.required && editable && (
            <RequiredIcon style={{ display: 'flex', position: 'absolute', top: -10, left: -10 }} />
          )}
          <ReadUnderstoodField
            params={item}
            onChange={(value) => {
              onSave(item.id, { value });
              setFilledReadUnderstood(value);
            }}
            users={assignedUsers}
            dueDate={dueDate}
            assignedBy={assignedBy}
            activityId={activityId}
            activity={activity}
            filled={filledReadUnderstood}
          />
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'assetIncident') {
    return (
      <Grid container item alignItems="center">
        <Grid
          container
          component={Paper}
          variant="outlined"
          sx={{ my: 1, position: 'relative', border: 'none' }}
          id={item.id}
        >
          {item.title && (
            <Grid container style={{ padding: 12 }}>
              <Typography variant="h6" gutterBottom>
                {item.title}
              </Typography>
            </Grid>
          )}
          <Grid container style={{ padding: 12 }}>
            <AssetIncident
              initial={assetIncident}
              onResult={(value) => {
                setAssetIncident(value);
                onSave(item.id, { value });
              }}
              editable={editable}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'process') {
    return (
      <Grid container item alignItems="center">
        <Grid
          container
          component={Paper}
          variant="outlined"
          sx={{ my: 1, position: 'relative', border: 'none' }}
          id={item.id}
        >
          {item.required && editable && (
            <RequiredIcon style={{ display: 'flex', position: 'absolute', top: -10, left: -10 }} />
          )}
          <ListItem button style={{ borderRadius: 8 }} onClick={openProcess}>
            <Grid container alignItems="center" style={{ padding: '8px 0' }}>
              <ProcessIcon style={{ marginRight: '1rem', display: 'flex' }} />
              {processEntry ? (
                <>
                  <Typography variant="h6">{item.processTitle}</Typography>
                  <Typography variant="h6" color="textSecondary" style={{ marginLeft: '0.25em' }}>
                    {`- entry #${processEntry.number}`}
                  </Typography>
                </>
              ) : (
                <Typography variant="h6">{`Start process: ${item.processTitle}`}</Typography>
              )}
              {startingEntry && <CircularProgress color="primary" style={{ marginLeft: 'auto' }} size={30} />}
            </Grid>
          </ListItem>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'styledText') {
    return (
      <Grid container item>
        <Grid container component={Paper} variant="outlined" sx={{ borderRadius: 1, py: 1, px: 2, my: 1 }} id={item.id}>
          <Grid container>
            <Typography
              style={{
                fontSize: item.titleStyle?.fontSize || 16,
                textAlign: item.titleStyle?.textAlign || undefined,
                fontStyle: item.titleStyle?.italic ? 'italic' : undefined,
                color: item.titleStyle?.color || undefined,
                textDecoration: item.titleStyle?.underline ? 'underline' : undefined,
                fontWeight: item.titleStyle?.bold ? 600 : undefined,
                whiteSpace: 'break-spaces',
              }}
            >
              {item.text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'text') {
    return (
      <Grid container component={Paper} variant="outlined" sx={{ borderRadius: 1, py: 1, px: 2, my: 1 }} id={item.id}>
        {item.title && (
          <Grid container item>
            <Typography variant="h6" component="h1" gutterBottom>
              {item.title}
            </Typography>
          </Grid>
        )}
        <HtmlContent content={item.text} />
      </Grid>
    );
  }

  if (item.type === 'info') {
    return (
      <Grid container component={Paper} variant="outlined" sx={{ borderRadius: 1, py: 1, px: 2, my: 1 }} id={item.id}>
        {item.title && (
          <Grid container item>
            <Typography variant="h6" component="h1" gutterBottom>
              {item.title}
            </Typography>
          </Grid>
        )}
        <Grid container item alignItems="flex-start" alignContent="flex-start">
          <Grid container item xs={isEmpty(item.files) ? 12 : 8}>
            <HtmlContent content={item.text} />
          </Grid>
          {!isEmpty(item.files) && (
            <Grid container item xs={4} style={{ paddingLeft: 4 }}>
              <PicturesCarousel pictures={item.files} />
            </Grid>
          )}
        </Grid>
        {addedInfo && (
          <Grid item container alignContent="flex-start">
            <Grid container item xs={8}>
              <RichTextField
                editable={editable}
                placeholder="Click to add text"
                onChange={(text) => {
                  setInfoText(text);
                  activateTimer({ infoText: text, infoFiles });
                }}
                initial={infoText}
              />
            </Grid>
            <Grid container item xs={4} style={{ paddingLeft: 4 }}>
              <PicturesCarousel
                editable={editable}
                pictures={infoFiles}
                onChange={(files) => {
                  setInfoFiles(files);
                  activateTimer({ infoText, infoFiles: files });
                }}
              />
            </Grid>
          </Grid>
        )}
        {item.allowDuplicating && editable && (
          <Grid container item>
            {!addedInfo && (
              <Button
                startIcon={<AddRounded />}
                style={{ borderRadius: 8 }}
                variant="contained"
                color="primary"
                onClick={() => setAddedInfo(true)}
              >
                Add more info
              </Button>
            )}
            {addedInfo && (
              <Button
                onClick={checkDeletingInfo}
                style={{ borderRadius: 8, color: red[500] }}
                startIcon={<DeleteOutlineRounded />}
              >
                Delete
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    );
  }

  if (item.type === 'table' && Array.isArray(item.table?.columns)) {
    return (
      <Grid container item>
        <Grid container component={Paper} variant="outlined" sx={{ borderRadius: 1, py: 1, px: 2, my: 1 }} id={item.id}>
          <Grid container item>
            <Typography variant="h6" gutterBottom>
              {item.title}
            </Typography>
          </Grid>
          <FieldDataGrid
            columns={item.table.columns}
            initial={tableRows}
            forceUpdate={tableDialogOpen || item.table.forceUpdate}
            editable={editable}
            onChange={saveFieldGrid}
            hideCalculations={hideCalculations}
            activityInfo={activityInfo}
            allCreatedActions={allCreatedActions}
            onCreatedAction={onCreatedAction}
            onDeletedAction={onDeletedAction}
          />
          <Grid container item sx={{ mt: 1 }}>
            <Button
              startIcon={<SettingsOverscanRounded />}
              onClick={() => setTableDialogOpen(true)}
              style={{ color: grey[700], marginLeft: 'auto' }}
            >
              open in dialog
            </Button>

            <TableDialog
              title={item.title}
              columns={item.table.columns}
              rows={tableRows}
              editable={editable}
              onChange={saveFieldGrid}
              open={tableDialogOpen}
              onClose={() => setTableDialogOpen(false)}
              activityInfo={activityInfo}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'task') {
    return (
      <Grid container item>
        <Grid
          container
          component={Paper}
          variant="outlined"
          sx={{ borderRadius: 1, py: 1, px: 2, my: 1, position: 'relative' }}
          id={item.id}
        >
          {item.required && editable && (
            <RequiredIcon style={{ display: 'flex', position: 'absolute', top: -10, left: -10 }} />
          )}
          <Grid container item alignItems="center" wrap="nowrap">
            <TaskIcon />
            <Typography variant="h6" component="h1" style={{ marginLeft: '0.7rem' }}>
              {item.title}
            </Typography>
            <StatusButton
              isDisabled={!editable}
              status={taskStatus}
              setStatus={saveTaskStatus}
              style={{ marginLeft: 'auto' }}
            />
            {(item.task?.description || item.task?.attachments?.length > 0) && (
              <IconButton onClick={() => setTaskExpanded(!taskExpanded)} style={{ marginLeft: '1rem' }}>
                {taskExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </Grid>
          <Collapse in={taskExpanded} style={{ width: '100%' }}>
            {item.task?.description && <HtmlContent content={item.task.description} />}
            {item.task?.attachments?.length > 0 && <FileViewer files={item.task?.attachments} />}
          </Collapse>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'resources') {
    return (
      <Grid container item>
        <Grid container component={Paper} variant="outlined" sx={{ borderRadius: 1, py: 1, px: 2, my: 1 }} id={item.id}>
          <Grid container item alignItems="center" wrap="nowrap">
            <FileCopyRounded style={{ color: blue[900] }} />
            <Typography variant="h6" component="h1" style={{ marginLeft: '0.7rem' }}>
              {item.title}
            </Typography>
          </Grid>
          <Grid container item sx={{ py: 1 }}>
            <FileViewer title="" files={item.files} />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'field' || (!item.type && item.fieldType)) {
    let content = <></>;

    if (item.fieldType === 'text') {
      if (editable) {
        content = (
          <DebouncedTextField
            variant="standard"
            fullWidth
            value={text}
            onValueChange={saveText}
            inputProps={{ maxLength: 128 }}
            id={`text-input-${item.id}`}
          />
        );
      } else {
        content = hasValue ? <Typography>{text}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'email') {
      if (editable) {
        content = (
          <DebouncedTextField
            placeholder="Email"
            variant="standard"
            fullWidth
            value={text}
            type="email"
            id={`${item.id}-email`}
            error={error && !!text}
            helperText={error && !!text ? 'Enter a correct email' : ''}
            onValueChange={(value) => {
              const email = String(value).toLowerCase();
              setError(!validateEmail(email));
              saveText(email);
            }}
            inputProps={{ maxLength: 128 }}
          />
        );
      } else {
        content = hasValue ? <Typography>{text}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'phone') {
      if (editable) {
        content = (
          <DebouncedTextField
            placeholder="Phone number"
            variant="standard"
            fullWidth
            value={text}
            type="tel"
            id={`${item.id}-tel`}
            error={error && !!text}
            helperText={error && !!text ? 'Enter a correct phone number' : ''}
            onValueChange={(val) => {
              const phone = String(val).replace(/[^\d+]/g, '');
              setError(!validateUkNumber(phone));
              saveText(phone);
            }}
            inputProps={{ maxLength: 128 }}
          />
        );
      } else {
        content = hasValue ? <Typography>{text}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'textArea') {
      if (editable) {
        /*content = (
          <DebouncedTextField
            fullWidth
            variant="outlined"
            multiline
            minRows={5}
            maxRows={30}
            value={text}
            inputProps={{ maxLength: 16384 }}
            onValueChange={saveText}
            id={`text-area-input-${item.id}`}
          />
        );*/
        content = (
          <RichTextField disableImages initial={text} onChange={saveText} onDone={saveText} editOpen hideButton />
        );
      } else {
        //content = hasValue ? <Typography style={{ whiteSpace: 'break-spaces' }}>{text}</Typography> : noValue;
        content = <HtmlText value={text} />;
      }
    }

    if (item.fieldType === 'number') {
      if (editable) {
        content = (
          <DebouncedTextField
            type="number"
            variant="standard"
            value={number}
            onValueChange={(value) => saveNumber(value === '' ? '' : toNumber(value))}
            inputProps={{ maxLength: 32 }}
            id={`number-input-${item.id}`}
            InputProps={{
              startAdornment: item.numberOptions ? (
                <>
                  {item.numberOptions.format === 'dollar' && <AttachMoneyRounded fontSize="small" />}
                  {item.numberOptions.format === 'pound' && <CurrencyPoundRounded fontSize="small" />}
                  {item.numberOptions.format === 'euro' && <EuroRounded fontSize="small" />}
                  {item.numberOptions.format === 'percent' && <PercentRounded fontSize="small" />}
                </>
              ) : null,
            }}
          />
        );
      } else {
        content = hasValue ? (
          <>
            {number != null && (
              <>
                {item.numberOptions?.format === 'dollar' && <AttachMoneyRounded fontSize="small" />}
                {item.numberOptions?.format === 'pound' && <CurrencyPoundRounded fontSize="small" />}
                {item.numberOptions?.format === 'euro' && <EuroRounded fontSize="small" />}
              </>
            )}

            <Typography>{number}</Typography>
            {number != null && item.numberOptions?.format === 'percent' && <PercentRounded fontSize="small" />}
          </>
        ) : (
          noValue
        );
      }
    }

    if (item.fieldType === 'calculation') {
      content = (
        <>
          {text && (
            <>
              {item.expression?.format === 'dollar' && <AttachMoneyRounded fontSize="small" />}
              {item.expression?.format === 'pound' && <CurrencyPoundRounded fontSize="small" />}
              {item.expression?.format === 'euro' && <EuroRounded fontSize="small" />}
            </>
          )}

          <Typography>{text != null ? text : '-'}</Typography>
          {text && item.expression?.format === 'percent' && <PercentRounded fontSize="small" />}
        </>
      );
    }

    if (item.fieldType === 'date') {
      if (editable) {
        content = (
          <DatePicker disableContainer value={date} onChange={(date) => saveDate(moment(date).format('YYYY-MM-DD'))} />
        );
      } else {
        content = hasValue ? <Typography>{moment(date).format('DD/MM/yyyy')}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'time') {
      if (editable) {
        content = (
          <TimePicker
            disableContainer
            value={date}
            onChange={(date) =>
              saveDate(
                date
                  ? moment(date).set({ year: 2000, month: 0, date: 1, seconds: 0, milliseconds: 0 }).toISOString()
                  : null,
              )
            }
          />
        );
      } else {
        const parsed = moment(date, true);
        content = hasValue && parsed.isValid() ? <Typography>{parsed.format('hh:mm A')}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'datetime') {
      if (editable) {
        content = (
          <DateTimePicker
            disableContainer
            value={date}
            onChange={(date) => saveDate(date ? moment(date).format('YYYY-MM-DD HH:mm') : null)}
          />
        );
      } else {
        const parsed = moment(date, true);
        content =
          hasValue && parsed.isValid() ? <Typography>{parsed.format('DD/MM/yyyy hh:mm A')}</Typography> : noValue;
      }
    }

    // === OPTION TYPES ===

    if (item.fieldType === 'dropbox' || item.fieldType === 'weightedList') {
      if (editable) {
        if (loadingOptions) {
          // waiting for the global fields options
          content = <CircularProgress size={24} />;
        } else {
          const selected = dropboxValue ? fieldOptions.find((o) => o.id === dropboxValue) : null;
          const tree = GetOptionPath(selected, fieldOptions);

          content = (
            <>
              <Button
                style={{ textTransform: 'none', minWidth: 220 }}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
                endIcon={<ArrowDropDownRounded />}
                variant={'outlined'}
              >
                <Typography noWrap>{!isEmpty(tree) ? tree.join(' / ') : 'Select value'}</Typography>
              </Button>

              <SearchMenu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                items={fieldOptions}
                onResult={(optionId) => saveDropboxValue(optionId)}
                minWidth={220}
              />
            </>
          );
        }
      } else {
        // selectedOptions have priority
        const text = isArray(item.filledValue?.selectedOptions)
          ? item.filledValue.selectedOptions.find((o) => o.id === dropboxValue)?.text
          : fieldOptions.find((o) => o.id === dropboxValue)?.text;

        content = !isEmpty(text) ? <Typography>{text}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'status') {
      if (loadingOptions && editable) {
        // waiting for the global fields options
        content = <CircularProgress size={24} />;
      } else {
        const selected = editable
          ? dropboxValue
            ? fieldOptions.find((x) => x.id === dropboxValue)
            : null
          : isArray(item.filledValue?.selectedOptions)
          ? item.filledValue.selectedOptions.find((x) => x.id === dropboxValue)
          : fieldOptions.find((x) => x.id === dropboxValue);

        const textColor = selected?.color ? theme.palette.getContrastText(selected.color) : undefined;
        const disabled = !editable || item?.statusParams?.setAutomatically;

        content = (
          <>
            <Button
              style={{
                textTransform: 'none',
                background: selected ? selected.color : undefined,
                minWidth: 220,
              }}
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              endIcon={
                disabled ? null : (
                  <ArrowDropDownRounded sx={{ color: selected ? textColor : (theme) => theme.palette.primary.main }} />
                )
              }
              disabled={disabled}
              variant={selected ? 'text' : 'outlined'}
            >
              <Typography
                noWrap
                sx={{ fontWeight: 500, color: selected ? textColor : (theme) => theme.palette.primary.main }}
              >
                {selected ? selected.text : 'Select status'}
              </Typography>
            </Button>

            {editable && (
              <SearchMenu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                items={fieldOptions}
                onResult={(optionId) => saveDropboxValue(optionId)}
                minWidth={220}
                withColor
              />
            )}
          </>
        );
      }
    }

    if (item.fieldType === 'tickbox' && fieldOptions) {
      if (editable) {
        if (loadingOptions) {
          // waiting for the global fields options
          content = <CircularProgress size={24} />;
        } else {
          if (item.displayType === 'buttons') {
            content = (
              <Grid item container sx={{ mt: 1 }} spacing={2}>
                {fieldOptions.map((option) => (
                  <Grid item key={option.id}>
                    <Button
                      disabled={!editable}
                      variant="outlined"
                      sx={{
                        borderRadius: 3,
                        textTransform: 'none',
                        color: tickboxValues.includes(option.id)
                          ? theme.palette.getContrastText(theme.palette.primary.main)
                          : '',
                        background: tickboxValues.includes(option.id) ? theme.palette.primary.main : '',
                        '&:hover': {
                          background: tickboxValues.includes(option.id) ? theme.palette.primary.dark : '',
                        },
                      }}
                      onClick={() => handleTickbox(option.id, !tickboxValues.includes(option.id))}
                    >
                      {option.text}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            );
          } else {
            const notNested = fieldOptions.filter((x) => !x.parentId);

            content = (
              <Grid container sx={{ mt: 1 }}>
                {editable && (
                  <Grid container>
                    <Typography gutterBottom color={'text.secondary'}>
                      Select value{item.selectType === 'multiple' ? 's' : ''}
                    </Typography>
                  </Grid>
                )}
                <Grid container>
                  {notNested.map((option) => (
                    <CheckboxItem
                      key={option.id}
                      option={option}
                      editable={editable}
                      allOptions={fieldOptions}
                      onChange={handleTickbox}
                      checkedItems={tickboxValues}
                    />
                  ))}
                </Grid>
              </Grid>
            );
          }
        }
      } else {
        const selected = isArray(item.filledValue?.selectedOptions)
          ? item.filledValue.selectedOptions
          : fieldOptions.filter((o) => tickboxValues.includes(o.id));

        content = selected.map((option) => (
          <Grid container item key={option.id} sx={{ my: 0.5 }}>
            <Typography>{option.text}</Typography>
          </Grid>
        ));
      }
    }

    if (item.fieldType === 'conditional') {
      content = (
        <>
          <Grid item container sx={{ mt: 1 }}>
            {item.conditions &&
              item.conditions.map((condition) => (
                <Button
                  disabled={!editable}
                  key={condition.id}
                  variant="outlined"
                  style={{
                    color:
                      condition.id === selectedCondition.id
                        ? theme.palette.getContrastText(condition.color)
                        : condition.color,
                    marginRight: 16,
                    borderRadius: 24,
                    textTransform: 'none',
                    border: `2px solid ${condition.color}`,
                    background: condition.id === selectedCondition.id ? condition.color : '',
                  }}
                  onClick={() => saveConditional(condition)}
                >
                  {condition.text}
                </Button>
              ))}
          </Grid>
          {selectedCondition.id && ((editable && selectedCondition.task) || (!editable && task)) && !external && (
            <Grid item container style={{ margin: '1rem 0' }}>
              <GeneralButton
                endIcon={<TaskIcon />}
                style={{ fontWeight: task ? 700 : 400, border: task ? `2px solid ${blue[900]}` : '' }}
                onClick={() => {
                  if (editable) {
                    setTaskParams(selectedCondition.task);
                    setTaskDialogOpen(true);
                  } else {
                    StateManager.selectTask(task._id);
                  }
                }}
              >
                Create a task
              </GeneralButton>

              {editable && (
                <TaskDialog
                  open={taskDialogOpen}
                  onClose={() => setTaskDialogOpen(false)}
                  params={taskParams}
                  onResult={saveTask}
                  task={task}
                />
              )}
            </Grid>
          )}
          {selectedCondition.id &&
            ((editable && selectedCondition.templateId) || (!editable && project)) &&
            !external && (
              <Grid item container style={{ margin: '1rem 0' }}>
                <GeneralButton
                  endIcon={<ProjectIcon />}
                  style={{ fontWeight: project ? 700 : 400, border: project ? `2px solid ${blue[900]}` : '' }}
                  onClick={() => {
                    if (editable) {
                      setProjectDialogOpen(true);
                    } else {
                      history.push(`/tasks/projects/project/${project._id}`);
                    }
                  }}
                >
                  Create a project
                </GeneralButton>

                {editable && (
                  <ProjectDialog
                    open={projectDialogOpen}
                    onClose={() => setProjectDialogOpen(false)}
                    project={project}
                    onResult={saveProject}
                  />
                )}
              </Grid>
            )}
          {selectedCondition.id && (
            <>
              {(() => {
                if (!allItems) return null;
                const hidden = isArray(hiddenItems) ? hiddenItems : [];
                const fields = allItems.filter(
                  (x) => x.conditionalFieldId === selectedCondition.id && !hidden.includes(x.id),
                );
                if (isEmpty(fields)) return null;
                return (
                  <Grid item container sx={{ borderTop: `2px solid ${grey[300]}`, py: 2, px: 1, mt: 2 }}>
                    {fields.map((field) => (
                      <Field
                        key={field.id}
                        item={field}
                        onSave={onSave}
                        editable={editable}
                        greyOut={greyOut}
                        activityInfo={activityInfo}
                        onCreatedAction={onCreatedAction}
                        onDeletedAction={onDeletedAction}
                        allCreatedActions={allCreatedActions}
                      />
                    ))}
                  </Grid>
                );
              })()}
            </>
          )}
        </>
      );
    }

    if (item.fieldType === 'image' || item.fieldType === 'file') {
      if (editable) {
        content = (
          <Uploader
            onlyImages={item.fieldType === 'image'}
            onChange={uploadFiles}
            uploaded={files}
            withLinkedFeatures={item.fieldType !== 'image'}
          />
        );
      } else {
        content = <FileViewer files={files} />;
      }
    }

    if (item.fieldType === 'user') {
      if (external) {
        content = (
          <ExternalUserField
            selected={selectedUsers}
            editable={editable}
            onResult={(userId) => {
              setSelectedUsers(userId);
              save(userId);
            }}
          />
        );
      } else {
        content = (
          <>
            {editable &&
              (allowMultipleUsers ? (
                <GeneralButton
                  onClick={selectMultipleUsers}
                  startIcon={<GroupRounded style={{ color: grey[500] }} />}
                  style={{ marginRight: '1rem' }}
                >
                  Select users
                </GeneralButton>
              ) : (
                <GeneralButton
                  onClick={selectUser}
                  startIcon={<Person style={{ color: grey[500] }} />}
                  style={{ marginRight: '1rem' }}
                >
                  Select user
                </GeneralButton>
              ))}
            {isString(selectedUsers) && <User id={selectedUsers} />}
            {isArray(selectedUsers) && !isEmpty(selectedUsers) && <UserGroup ids={selectedUsers} />}
          </>
        );
      }
    }

    if (item.fieldType === 'people') {
      content = (
        <PeopleCard
          initial={people}
          editable={editable}
          options={item.peopleOptions}
          onChange={(value) => {
            setPeople(value);
            save(value);
          }}
        />
      );
    }

    if (item.fieldType === 'dataSet') {
      content = (
        <DataSetField params={item.dataSetParams} onResult={saveSetRows} value={dataSetRows} editable={editable} />
      );
    }

    if (item.fieldType === 'signature') {
      if (!editable && !hasValue) {
        content = noValue;
      } else {
        content = <TabbedSignature onResult={saveSignature} initial={signature} editable={editable} />;
      }
    }

    if (item.fieldType === 'table') {
      if (!editable && !hasValue) {
        content = noValue;
      } else {
        content = (
          <FieldTable
            params={item.table || item.tableParams}
            onResult={saveTable}
            initial={table}
            editable={editable}
          />
        );
      }
    }

    if (item.fieldType === 'actionGroup') {
      content = (
        <ActionGroup
          controlled
          itemId={item.id}
          activityInfo={activityInfo}
          preSetActions={item.preSetActions}
          editable={editable}
          onResult={onCreatedAction}
          allCreatedActions={allCreatedActions}
          forCell={Boolean(cellInfo)}
          cellInfo={cellInfo}
          onDelete={onDeletedAction}
        />
      );
    }

    if (item.fieldType === 'readAndSign') {
      content = (
        <ReadAndSign
          item={item}
          config={item.readAndSignOptions}
          onResult={saveReadDocuments}
          initial={readDocuments}
          editable={editable}
        />
      );
    }

    if (item.fieldType === 'humanMap') {
      content = <HumanBody editable={editable} initial={selectedBodyParts} onChange={saveBodyParts} />;
    }

    return (
      <Grid container item>
        <Grid
          container
          component={Paper}
          variant="outlined"
          sx={{ borderRadius: 1, py: 1, px: 2, my: 1, position: 'relative' }}
          id={item.id}
        >
          {item.required && editable && item.fieldType !== 'actionGroup' && (
            <RequiredIcon style={{ display: 'flex', position: 'absolute', top: -10, left: -10 }} />
          )}
          <Grid container alignItems={item.title.length > 50 ? 'baseline' : 'center'} wrap="nowrap">
            <LabelRounded fontSize="small" style={{ color: greyOut ? grey[500] : amber[500] }} />
            <Typography
              variant="h6"
              style={{ color: greyOut ? grey[500] : '', marginLeft: '0.7rem', whiteSpace: 'break-spaces' }}
            >
              {item.title}
            </Typography>
            <div style={{ marginLeft: 'auto' }} />
            {item.editedAt && (
              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="textSecondary" style={{ marginLeft: '1rem', fontSize: 14 }} noWrap>
                  {`Edited ${FormatDate(item.editedAt)} ${item.userId ? 'by' : ''}`}
                </Typography>
                {item.userId && <UserChip size="small" id={item.userId} margin="0 0 0 8px" />}
              </Grid>
            )}
            {item.helpText && (
              <HtmlTooltip placement="left" title={item.helpText}>
                <HelpRounded style={{ color: grey[500] }} />
              </HtmlTooltip>
            )}
          </Grid>
          {!greyOut &&
            item.prevValues &&
            editable &&
            item.prevValues[0] &&
            item.fieldType !== 'file' &&
            item.fieldType !== 'image' && (
              <Grid container style={{ margin: '1rem 0' }}>
                <Typography gutterBottom style={{ fontWeight: 700 }}>
                  Previously filled:
                </Typography>
                {item.prevValues.map((value, index) => (
                  <Grid container item key={index} alignItems="center">
                    <Typography gutterBottom color="textSecondary" style={{ fontSize: 14, marginRight: '1rem' }}>
                      {getPrevValueText(value.value)}
                    </Typography>
                    <Button
                      size="small"
                      style={{ textTransform: 'none', color: grey[700] }}
                      startIcon={<FileCopy style={{ color: grey[500] }} />}
                      onClick={() => copyPrevValue(value.value)}
                    >
                      Copy
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          {!greyOut && !withoutValue && (
            <Grid container item alignItems="center" sx={{ py: 1 }}>
              {content}
            </Grid>
          )}
          {!isEmpty(fieldExecutedActions) && (
            <Grid container item alignItems="center" sx={{ py: 1 }}>
              {fieldExecutedActions.map((action, index) => (
                <Action key={index} initial={action} onDelete={onExecutedActionDelete} editable={editable} />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'image') {
    return item.picture ? (
      <Grid container item>
        <Grid container component={Paper} variant="outlined" sx={{ borderRadius: 1, py: 1, px: 2, my: 1 }} id={item.id}>
          {item.title && (
            <Typography variant="h6" component="h1" gutterBottom>
              {item.title}
            </Typography>
          )}
          <Grid container item>
            <img alt={item.picture.name} src={item.picture.location} style={{ maxWidth: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
    ) : null;
  }

  return (
    <Grid container item>
      This item is not supported
    </Grid>
  );
}
