import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  Tooltip,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { isArray, isEmpty } from 'lodash';
import MiniField from '../Fields/MiniField';
import { AssetIcon } from '../Icons';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { RoundedDialog, StandardDialogActions } from '../Components';
import axios from 'axios';

export default function AssetReference({ assetId, ...props }) {
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState(null);
  const [fieldsDialog, setFieldsDialog] = useState(false);

  useEffect(() => {
    if (!assetId) return;
    setLoading(true);

    axios
      .get('/asset/getAssetInfo', { params: { assetId } })
      .then(({ data }) => {
        setAsset(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [assetId]);
  const pattern = '';
  let itemsCount = 0;
  return (
    <Grid container direction={'row'} alignItems={'center'} wrap="nowrap" justifyContent={'space-between'} {...props}>
      {loading ? (
        <CircularProgress />
      ) : (
        asset && (
          <>
            <Grid
              container
              item
              direction={'row'}
              maxWidth={'fit-content'}
              gap={2}
              alignItems={'center'}
              justifyContent={'center'}
              marginRight={3}
            >
              <Typography>
                This entry is for asset{' '}
                {asset.prefix ? (
                  <span style={{ color: blue[500], fontWeight: 500 }}>
                    {asset.prefix}-{asset.number}
                    {'   '}
                  </span>
                ) : (
                  <span style={{ color: blue[500], fontWeight: 500 }}>
                    {asset.number}
                    {'   '}
                  </span>
                )}
                {'   '}
                <span style={{ fontWeight: 500 }}>{asset.title}</span>
              </Typography>
            </Grid>
            <Grid
              container
              item
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              justifyContent={'flex-start'}
              flexWrap={'nowrap'}
              width={'100%'}
            >
              {asset.fields.filter((field) => field.fieldType !== 'textArea').length > 3 && (
                <Grid container item flexWrap={'nowrap'} maxWidth={'fit-content'} flexGrow={2}>
                  <Tooltip title="Show all fields">
                    <IconButton icon onClick={() => setFieldsDialog(true)}>
                      <VisibilityIcon p={0} />
                    </IconButton>
                  </Tooltip>
                  <FieldsDialog asset={asset} open={fieldsDialog} onClose={() => setFieldsDialog(false)} />
                </Grid>
              )}

              <Grid container item flexWrap={'nowrap'} gap={2} flexGrow={2}>
                {!isEmpty(asset.fields) &&
                  isArray(asset.fields) &&
                  asset.fields.map((field, i) => {
                    // const fullTextClone =
                    //   field.fieldType === 'textArea' &&
                    //   field.filledValue.value.replace(/<[^>]*>/g, '').split('').length >= 15
                    //     ? field.filledValue.value.replace(/<[^>]*>/g, '')
                    //     : null;
                    // const textValue =
                    //   field.fieldType === 'textArea' &&
                    //   field.filledValue.value.replace(/<[^>]*>/g, '').split('').length >= 15
                    //     ? `${field.filledValue.value.replace(/<[^>]*>/g, '').slice(0, 15)}...`
                    //     : field.filledValue.value;
                    // field.filledValue.value = textValue;
                    if (itemsCount < 3 && field.fieldType !== 'textArea') {
                      itemsCount++;
                      const clonedFullText = field.filledValue.value;
                      if (field.fieldType === 'text' && field?.filledValue?.value?.length > 24) {
                        field.filledValue.value = `${field.filledValue.value.slice(0, 22)}...`;
                      }
                      return (
                        <Tooltip key={field.id} title={field?.filledValue?.value?.length > 24 ? clonedFullText : null}>
                          <Grid
                            item
                            flexGrow={1}
                            style={{
                              height: '30px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              maxWidth: 'fit-content',
                            }}
                          >
                            <MiniField
                              emptyAsNull
                              field={field}
                              whiteSpace={'nowrap'}
                              initial={field.filledValue}
                              searchPattern={pattern}
                            />
                          </Grid>
                        </Tooltip>
                      );
                    }
                  })}
              </Grid>
            </Grid>
            <Grid container item flexGrow={1} justifyContent={'flex-end'} maxWidth={'fit-content'}>
              <Link to={`/asset/${assetId}`}>
                <Button startIcon={<AssetIcon />}>Go to the asset</Button>
              </Link>
            </Grid>
          </>
        )
      )}
    </Grid>
  );
}

function FieldsDialog({ open, onClose, asset }) {
  return (
    <RoundedDialog open={open} onClose={onClose}>
      <DialogTitle>Asset fields</DialogTitle>
      <DialogContent>
        {asset.fields.map((field) => {
          if (field.fieldType !== 'textArea') {
            return (
              <Tooltip key={field.id}>
                <Grid
                  item
                  style={{
                    flexGrow: 0,
                    height: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <MiniField emptyAsNull field={field} initial={field.filledValue} searchPattern={''} />
                </Grid>
              </Tooltip>
            );
          }
        })}
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        additionalActions={
          <Grid container item flexGrow={1} justifyContent={'flex-end'} maxWidth={'fit-content'}>
            <Link to={`/asset/${asset._id}`}>
              <Button startIcon={<AssetIcon />}>Go to the asset</Button>
            </Link>
          </Grid>
        }
      />
    </RoundedDialog>
  );
}
